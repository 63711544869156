.async-loader {
  .background {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    background: rgba(#fff, 0.9);
    z-index: 100000;
    width: 100vw;
    height: 100vh;

    &.progress-bar-background {
      background: #fff;
    }
  }

  .animation {
    z-index: 100000;
    position: absolute;
    top: 45%;
    left: 47%;

    .loader {
      border: 8px solid $c-blue-primary;
      border-top: 8px solid $c-blue-dark;
      opacity: 0.7;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      animation: spin 2s linear infinite;
    }
  }

  .progress-bar {
    z-index: 100000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40em;
    max-width: calc(100% - 3em);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loader {
      border: 8px solid $c-blue-primary;
      border-top: 8px solid $c-blue-dark;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      animation: spin 2s linear infinite;
      position: absolute;
      top: 0;
      left: 0;

      &-container {
        position: relative;
        width: 150px;
        height: 150px;
        margin-bottom: 2em;

        .percent-text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 2.75em;
        }
      }
    }

    .text {
      max-width: 100%;
      width: 100%;
      overflow: hidden;
      font-size: 2em;
      text-align: center;
    }

    @media (max-width: $breakpoint-tablet), (max-height: $breakpoint-mobile) {
      .text {
        font-size: 1.5em;
      }
    }
  }
}

.component-loader {
  .loader {
    border: 8px solid $c-blue-primary;
    border-top: 8px solid $c-blue-dark;
    opacity: 0.7;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
