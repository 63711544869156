.eraserBroom {
  cursor: url("../../../../assets/img/erasers/broom.png") 10 40, auto;
}

.eraserBrush {
  cursor: url("../../../../assets/img/erasers/brush.png") 10 20, auto;
}

.eraserChisel {
  cursor: url("../../../../assets/img/erasers/chisel.png") 10 20, auto;
}

.eraserEraser {
  cursor: url("../../../../assets/img/erasers/eraser.png") 10 40, auto;
}

.eraserHammer {
  cursor: url("../../../../assets/img/erasers/hammer.png") 10 20, auto;
}

.eraserStick {
  cursor: url("../../../../assets/img/erasers/stick.png") 10 20, auto;
}

.eraserTowel {
  cursor: url("../../../../assets/img/erasers/towel.png") 10 20, auto;
}

.eraserWipetowel {
  cursor: url("../../../../assets/img/erasers/wipe_towel.png") 10 20, auto;
}
