.screen {
  padding-left: 0.5em;
  padding-right: 0.5em;
  //margin-bottom: 3.8em;
  padding-bottom: 2em;

  .screen-two-cols {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  @media (min-width: $breakpoint-desktop) {
    .screen-two-cols {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .small-text-field {
    width: 50px;
  }

  .small-margin {
    margin: 0px 5px;
  }

  .table-with-help {
    width: calc(100% - 2em);
  }

  .start {
    &-authors {
      &-filter {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left-filter {
          display: flex;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
        }

        @media (max-width: $breakpoint-tablet) {
          flex-direction: column;
          justify-content: center;
        }

        @media (max-width: $breakpoint-mobile) {
          .left-filter {
            flex-wrap: wrap;
          }
        }
      }
    }
  }

  .carousel-container {
    width: 100%;
    display: flex;
    justify-content: center;

    .carousel-card {
      padding: 0px;

      &-text {
        width: 100%;
        height: 100%;
        padding: 0px;
        position: relative;

        .icon {
          &-left {
            position: absolute;
            left: 0px;
            bottom: 0px;
          }
          &-middle {
            position: absolute;
            left: 20px;
            bottom: 0px;
          }
          &-right {
            position: absolute;
            right: 0px;
            bottom: 0px;
          }
        }

        &-image {
          position: absolute;
          left: 0px;
          top: 0px;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &.screen-with-select-on-bottom {
    padding-bottom: 20em;

    &-small {
      padding-bottom: 15em;
    }
  }

  .description-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;

    .part {
      max-width: calc(100% - 2em);
    }
  }

  @media (min-width: $breakpoint-desktop) {
    .description-container {
      flex-direction: row;
      justify-content: space-around;

      .part {
        width: 45%;
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (min-width: $breakpoint-mobile) {
    .row {
      flex-wrap: nowrap;
    }
  }

  &-image {
    .image-infopoints-container {
      width: 100%;
      height: 100%;
      user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -ms-user-select: none;

      .infopoint {
        &-icon {
          position: absolute;
          cursor: move;
          color: $c-blue-primary;
          font-size: 34px !important;
        }

        &-tooltip {
          background-color: $c-blue-primary;

          &.place-top {
            &:after {
              border-top-color: $c-blue-primary !important;
              border-top-style: solid !important;
              border-top-width: 6px !important;
            }
          }

          &.place-right {
            &:after {
              border-right-color: $c-blue-primary !important;
              border-right-style: solid !important;
              border-right-width: 6px !important;
            }
          }

          &.place-left {
            &:after {
              border-left-color: $c-blue-primary !important;
              border-left-style: solid !important;
              border-left-width: 6px !important;
            }
          }

          &.place-bottom {
            &:after {
              border-bottom-color: $c-blue-primary !important;
              border-bottom-style: solid !important;
              border-bottom-width: 6px !important;
            }
          }
        }
      }
    }
  }

  img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .one-image-row {
    justify-content: center;
    margin-bottom: 2em;
    max-width: 49%;
    & > * {
      max-width: 100%;
    }
  }

  @media (max-width: $breakpoint-desktop) {
    .one-image-row {
      max-width: 100%;
    }
  }

  .card-image {
    position: relative;
    padding: 0px;
    max-width: 100%;
    display: inline-block;
    overflow: auto;

    &.img-none {
      width: 450px;
      height: 350px;

      @media (max-width: $breakpoint-mobile) {
        .card-image-icon-placeholder {
          font-size: 60vw;
        }
      }
    }

    &.img {
      width: 450px;
      height: 350px;
    }

    &.auto-height {
      height: 100%;
    }

    &-container {
      min-width: 100px;
      max-width: 100%;
    }

    &-wrap {
      display: flex;
      flex-direction: column;
    }

    i {
      font-size: 18em;
    }

    .placeholder {
      position: relative;
      cursor: pointer;
      p {
        font-size: 2em;
        position: absolute;
        white-space: nowrap;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      i {
        color: #ececec;
      }
    }
  }

  .select-field {
    margin-right: 5em;
    max-width: 100%;

    &.bigger {
      min-width: 250px;
    }

    &.big {
      min-width: 300px;
    }

    @media (max-width: $breakpoint-mobile) {
      &.big {
        min-width: 250px;
      }
    }
  }

  .table-infopoints {
    margin-top: 1em;

    &:before {
      line-height: 1em;
      content: attr(label);
    }
  }

  .game-options-answers {
    max-width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;

    .answer {
      display: flex;
      flex-direction: column;
      margin-left: 1em;
      margin-right: 1em;
    }

    @media (max-width: $breakpoint-desktop-big) {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .answer {
        max-width: calc(100% - 2em);
        margin-left: 1em;
        margin-right: 1em;
        padding-bottom: 2em;
      }
    }
  }
}

.fixed-bottom-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: $c-blue-dark;
  height: 3.8em;
  .inner {
    max-width: 1340px;
    margin: 0 auto;
    height: 3.8em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn {
      background: $c-white;
    }
  }
}

@keyframes infopoint-pulse {
  50% {
    font-size: 40px;
  }
}
