@use "../variables.scss" as vars;

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: 400px;
  max-height: 90%;
  z-index: 10001;
  background-color: $c-white;
  opacity: 1;
  filter: alpha(opacity=100);
  overflow: auto;
  display: flex;
  flex-direction: column;

  &.big {
    width: 100%;
    max-width: 600px;
  }

  &.large {
    width: 100%;
    max-width: 1000px;
  }

  &-background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 10000;
    background-color: black;
    opacity: 0.5;
    filter: alpha(opacity=50);

    &.warning {
      z-index: 1000000;
    }
  }
  &-close {
    cursor: pointer;
    &:hover {
      color: $c-blue-primary;
    }
  }
  &-content {
    width: 100%;
    margin-bottom: 1em;
    //overflow: auto;

    &.no-margin-bottom {
      margin-bottom: 0;
    }
  }
  &-title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid vars.$c-black-100;
  }
  &-title {
    flex: 1; // take all available space
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0;
  }
  &-menu {
    position: sticky;
    bottom: -2px;
    right: 0;
    height: 3.8em;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: $c-white;
  }
  // !! TODO - fix large dialogs on mobile
  &.large {
    width: 95vw;
    max-width: 1340px;
    max-height: 95vh;
  }
  &.warning {
    z-index: 1000001;
  }
}

.screen-document-new-url-selectfield ul {
  position: static;
}

.screen-document-new-file-selectfield ul {
  position: static;
}

.screen-document-change-url-selectfield ul {
  position: static;
}

.screen-document-change-file-selectfield ul {
  position: static;
}

@media (max-width: $breakpoint-md) {
  .dialog {
    width: 95vw;
    max-width: 100vw;
    max-height: 80vh;
    border: 0;

    &.big {
      width: 95vw;
      max-width: 600px;
    }

    &.large {
      width: 95vw;
      max-width: 1000px;
    }
  }
}

.radio {
  &-row {
    display: block;
  }
  &-option {
    display: inline-block;
  }
}

.chip {
  margin-top: 1em;
  margin-right: 1em;
}
