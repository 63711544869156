@import "../variables.scss";

/* -- Preview video section -- */
.preview-video-section {
  position: relative;
  width: 100%;
  height: 100svh;

  background-color: $primary-color;
  color: white;
}

.preview-video-section h2 {
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
}

@media (min-width: 576px) {
  .preview-video-section h2 {
    font-size: 40px;
    line-height: 58px;
  }
}

@media (min-width: 768px) {
  .preview-video-section h2 {
    font-size: 48px;
    line-height: 58px;
  }
}
