.popper[data-popper-placement^="top"] > .arrow {
  bottom: -4px;
}

.popper[data-popper-placement^="bottom"] > .arrow {
  top: -4px;
}

.popper[data-popper-placement^="left"] > .arrow {
  right: -4px;
}

.popper[data-popper-placement^="right"] > .arrow {
  left: -4px;
}
