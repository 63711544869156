@import "../variables.scss";

/* -- INTRO section -- */

.intro-section {
  position: relative;
  width: 100%;
  height: 100svh;
}

.intro-section .video-background-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $primary-color;
  opacity: 0.85;
  z-index: 1;
}

.intro-section video {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.intro-section .description {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.intro-section .description {
  color: white;
}

// .. on very very small screens, it is even possible to make font even smaller
.intro-section .description h1 {
  font-size: 30px;
  line-height: 35px;
  font-weight: 300;
}

@media (min-width: 380px) {
  .intro-section .description h1 {
    font-size: 32px;
    line-height: 38px;
  }
}

@media (min-width: 576px) {
  .intro-section .description h1 {
    font-size: 40px;
    line-height: 48px;
  }
}

@media (min-width: 768px) {
  .intro-section .description h1 {
    font-size: 56px;
    line-height: 68px;
  }
}

// mobile landscape mode
@media (orientation: landscape) and (max-height: 500px) {
  .intro-section .description h1 {
    font-size: 32px;
    line-height: 38px;
  }
}

// - -

// .. on very very small screens, it is even possible to make font even smaller
.intro-section .description p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

@media (min-width: 380px) {
  .intro-section .description p {
    font-size: 15px;
    line-height: 22px;
  }
}

@media (min-width: 576px) {
  .intro-section .description p {
    font-size: 16px;
    line-height: 24px;
  }
}

// mobile landscape mode
@media (orientation: landscape) and (max-height: 500px) {
  .intro-section .description p {
    font-size: 15px;
    line-height: 22px;
  }
}

// - -

.intro-section .description a {
  margin-top: 8px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;

  cursor: pointer;
  color: $primary-color;
  background-color: white;
  border: 1px solid white;
  border-radius: 60px;
}

.intro-section .description a:hover {
  text-decoration: none;
  background-color: $primary-color;
  color: white;
  border-color: $primary-color;
}

.intro-section .scroll-down-container {
  position: absolute;
  bottom: 24px;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
}

.intro-section .scroll-down-link {
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
