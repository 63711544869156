.oauth-button {
  border-radius: 60px;
  color: white !important;
  font-size: 16px;
  font-weight: 400;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.oauth-button:hover {
  text-decoration: none;
}
